<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='goBack'/>
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <van-collapse-item :title="$t('pur.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('pur.采购订单号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.contractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.申请日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.signDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.作废状态') }}</h5>
          <p>{{ this.ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.完结状态') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.endStatus |setDict('FINAL_STATUS')  }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.特价申请单号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.purApplicationCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.业务类型') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.bussinessType | setDict('PUR_BUSINESS_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.供应商') }}(报关公司)</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.supplierName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.实际供应商名称') }}(厂商)</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.thirdCompanyName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.实际供应商英文名称') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.thirdCompanyUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.客户号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.clientNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.资金方式') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.fundMode |  setDict('FUND_MODE')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否境外采购') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.isPurAbroad |  setDict('PUB_Y_N')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购业务员') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.purSalesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购部门') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.purDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.产品组') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.productGroup | setDict('productGroup') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.产品大类') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.productCategories | setDict('PRODUCTCLASSIF') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.产品线') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.productLine | setDict('PRODUCTLINE')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.商机号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.businessNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.是否外采') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.isOutsource |  setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.外采类型') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.outSourceType |  setDict('CLASSIFICATION_OPTION') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购合同号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.purOrderNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.采购签订日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.purSignDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.收货方式') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.receivingWay |  setDict('RECEIVING_WAY') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.备注') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.remarks }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.作废原因') }}</h5>
          <p>{{ this.ruleForm.deleteReason }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.销售合同信息')" name="2" v-show="ruleForm.purCooperativeHeader.bussinessType==='1'">
        <div class="items">
          <h5>{{ $t('pur.销售订单号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.saleContractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.二级代理商') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.secondAryAgentName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.endUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户行业') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.endUserName | setDict('HANGYE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户行业') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.userIndustryInfo | setDict('HANGYE_MINGXI') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.用户所在地区') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.userArea }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售业务员') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.salesMan }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.区域') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.area | setDict('PUR_AREA')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.最终用户地址') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.finalUserAddress }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.销售合同号') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.saleOrderNo }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.合同条款')" name="3">
        <div class="items">
          <h5>{{ $t('pur.发票类型') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.invoiceType | setDict('SALE_INVOICE_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计交货日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.latestDeliveryDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.预计应收日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.expectedReceivaDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.金额信息')" name="4">
        <div class="items">
          <h5>{{ $t('pur.币种') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.currency | setDict('SPECIAL_CURRENCY')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.原币金额') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.amountUsd }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.公司指导汇率') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.guideRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.核算汇率') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.返点前金额') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.amountRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.返点后金额') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.amountAfterRebate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.返点总金额') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.totalAmountRebate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.返点信息')" name="5">
        <div v-for="(item, index) in ruleForm.purCooperativeHeader.purCooperativeRebateList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.返点编号') }}:</h5>
            <p>{{ item.rebateNo}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.返点类型') }}:</h5>
            <p>{{ item.rebateType | setDict('REBATETYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.返点金额') }}:</h5>
            <p>{{ item.rebateAmount}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.客户信息')" name="6" v-if="ruleForm.purCooperativeHeader.contractType === '2'">
        <div class="items">
          <h5>{{ $t('pur.客户名称') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.agentClientName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.发货方式') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.inventoryMethod | setDict('PUR_DELIVERY_METHOD') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.费率') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.agencyRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.国内保证金比例') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.domesticMarginRatio }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.保证金金额') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.depositAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.押汇利率') }}(年息)</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.interestRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.逾期利率') }}(月息)</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.overdueInterestRate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.押汇天数') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.negotiationDays }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.结算公式') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.settlementFormula }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.补充说明') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.postscript }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.付款方式')" name="6">
        <div v-for="(item, index) in ruleForm.purCooperativeHeader.purCooperativeErpList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.付款类型') }}:</h5>
            <p>{{ item.openLcMode | setDict('SALE_PAYMENT_METHOD')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.账期') }}:</h5>
            <p>{{ item.accPeriod}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.支付金额') }}:</h5>
            <p>{{ item.payAmount}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.比例') }}:</h5>
            <p>{{ item.proportion}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.备注') }}:</h5>
            <p>{{ item.remark}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.收货信息')" name="6" v-if="ruleForm.purCooperativeHeader.contractType === '2'">
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '1' || ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.联系人') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.contactName }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '1' || ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.联系人电话') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.contactNumber }}</p>
        </div>
        <div class="items" v-if="ruleForm.purCooperativeHeader.receivingWay === '1'">
          <h5>{{ $t('pur.存货仓库') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.warehouseName }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.寄存仓库') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.warehouseName }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '1'">
          <h5>{{ $t('pur.提货地址') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.deliveryAddress }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.寄存仓库地址') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.warehouseAddress }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.收货仓库') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.warehouseName }}</p>
        </div>
        <div class="items" v-show="ruleForm.purCooperativeHeader.receivingWay === '3'">
          <h5>{{ $t('pur.预计到货日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.harvestInstructionDate }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.商品明细')" name="7">
        <div v-for="(item, index) in ruleForm.purCooperativeHeader.purCooperativeLineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('pur.产品名称') }}:</h5>
            <p>{{ item.commodityDetails}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品编码') }}:</h5>
            <p>{{ item.materialCode}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品分类') }}:</h5>
            <p>{{ item.commodityCategoryName}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.规格型号') }}:</h5>
            <p>{{ item.specification}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品组') }}:</h5>
            <p>{{ item.productGroup | setDict('productGroup')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品线') }}:</h5>
            <p>{{ item.productLine | setDict('PRODUCTLINE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}:</h5>
            <p>{{ item.productType | setDict('PRODUCT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.产品类型') }}-二级分类:</h5>
            <p>{{ item.secondProductType | setDict('PRODUCT_SECOND_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.单位') }}:</h5>
            <p>{{ item.unitNameCn}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.税率') }}(%):</h5>
            <p>{{ item.vatRate}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.原币金额') }}:</h5>
            <p>{{ item.contactCurrency}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.返点前金额') }}:</h5>
            <p>{{ item.amountBeforeRebate}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.返点后单价') }}(未税):</h5>
            <p>{{ item.unitAmountAfterRebate}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.返点后金额') }}:</h5>
            <p>{{ item.amountAfterRebate}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.关联特价申请')" name="8">
        <div v-for="(item, index) in this.purApplicationContList" :key="index"
             style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('pur.申请单号') }}:</h5>
            <p style='text-decoration-line: underline' @click="gotoBusinessPage('pur/purApplication/applicationView', item)">{{ item.code}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.供应商名称') }}:</h5>
            <p>{{ item.supplierName}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.业务类型') }}:</h5>
            <p>{{ item.bussinessType | setDict('PUR_BUSINESS_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.状态') }}:</h5>
            <p>{{ item.status | setDict('PUB_WF_STATUS')}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.关联销售合同')" name="9">
        <div v-for="(item, index) in this.saleContList" :key="index"
             style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('pur.销售合同号') }}:</h5>
            <p style='text-decoration-line: underline' @click="gotoBusinessPage('sale/trade/saleTradeContractEdit', item)">{{ item.contractNo}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.合同类型') }}:</h5>
            <p>{{ item.contractType | setDict('SALE_TRADE_CONTRACT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('pur.状态') }}:</h5>
            <p>{{ item.status | setDict('PUB_WF_STATUS')}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('pur.系统信息')" name="10">
        <div class="items">
          <h5>{{ $t('pur.制单日期') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.制单人') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('pur.修改时间') }}</h5>
          <p>{{ this.ruleForm.purCooperativeHeader.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
    import {Dialog} from 'vant';
    import util from '../../../libs/util';
    import myHistoryPanel from '@/views/business/components/myHistoryPanel';
    import myApproval from '@/views/business/components/myApproval';

    export default {
        name: 'visitorFieldComponent',
        components: {
            myHistoryPanel,
            myApproval
        },
        data() {
            return {
                purApplicationContList: [],
                saleContList: [],
                loading: false,
                finished: false,
                activeNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                hostName: '',
                task: '',
                personId: '',
                approvalShow: false,
                customSubmitBtnName: ['同意', '不同意'],
                id: '',
                key: '',
                title: '',
                detail: null,
                active: 0,
                businessKey: '',
                woCode: 'purCooperativeAbolish',
                processInstanceName: '境外采购合同作废',
                mappingId: '',
                taskInstanceId: '',
                currentNode: '',
                variables: {},
                taskType: '',
                ruleForm: {
                    purCooperativeHeader: {
                        owner: '',
                        id: '',
                        isUse: 'N',
                        contractNo: '',
                        signOwnerId: '',
                        signOwner: '',
                        status: 'PREPARING',
                        endStatus: 'NOT_END',
                        contractType: '',
                        salesMan: '',
                        salesManId: '',
                        signDate: '',
                        effectiveDate: '',
                        supplierId: '',
                        supplierName: '',
                        tradeCountry: '',
                        foreignSupplierId: '',
                        foreignSupplierName: '',
                        expandSharing: '',
                        expandSharingName: '',
                        businessDescription: '',
                        isInsure: '',
                        priceTerms: '',
                        shippingType: '',
                        internationalShipping: '',
                        insuranceFactor: '110',
                        insuranceRate: '0.05',
                        internationalPremium: '',
                        departurePortDescribing: '',
                        destinationPortDescribing: '',
                        destinationPort: '',
                        destinationPortId: '',
                        deliveryDate: '',
                        expectedArrivalDate: '',
                        overShortRate: '10',
                        countryOfOrigin: '',
                        placeOfSigning: 'Beijing',
                        partialShipments: 'Y',
                        transhipment: 'Y',
                        interestRate: '',
                        overdueInterestRate: '',
                        negotiationDays: '',
                        postscript: '',
                        currency: '',
                        exchangeRateUsd: '',
                        exchangeRateRmb: '',
                        originalAmount: '0.00',
                        amountUsd: '0.00',
                        amountRmb: '0.00',
                        natureOfProcurement: '',
                        inventoryMethod: '',
                        professionalTemplate: '',
                        issuingEntrustment: '',
                        isGroupApproval: 'N',
                        forwardingCompanyName: '',
                        warehouseName: '',
                        agentClientName: '',
                        agentClientId: '',
                        agencyFeeMethod: '',
                        agencyRate: '',
                        rmbPerUsDollar: '0.00',
                        domesticMarginRatio: '',
                        depositAmount: '0.00',
                        serviceChargeExplain: '',
                        otherDepositDescription: '',
                        settlementFormula: '',
                        priceMarkup: '0.00',
                        purDeptName: '',
                        purDeptId: '',
                        shipmentPortName: '',
                        shipmentPortId: '',
                        latestShipmentDate: '',
                        openLcMode: '',
                        issuingBankName: '',
                        issuingBankId: '',
                        version: 0,
                        supplierContractNo: '',
                        settlementFormulaDescription: 'A:[(合同单价+加价)*数量*汇率+增值税+关税]*(1+代理费率)+银行费+保险费+仓储费\n' +
                            'B:[(合同单价+加价)*数量*汇率]*(1+代理费率)+增值税+关税+银行费+保险费+仓储费\n' +
                            'C:[(合同单价+加价))*数量*汇率+增值税+关税+银行费+保险费+仓储费]*(1+代理费率)\n' +
                            'D:(合同单价*数量*汇率+关税+增值税)*(1+代理费率)+银行费\n' +
                            'E:(合同单价*数量*汇率+关税*(1+增值税率)+增值税)*(1+代理费率)+银行费(1+增值税率)',
                        profitData: [],
                        purCooperativeLineList: [],
                        purCooperativeErpList: [],
                        purCooperativeProfitList: []
                    },
                    componentDisable: false,
                    id: '',
                    orgId: '',
                    deptId: '',
                    orderStatus: '',
                    processInstanceId: '',
                    status: '',
                    deleteReason: ''
                },
            };
        },
        props: {},
        methods: {
            gotoBusinessPage (name, item) {
                this.$router.push({
                    'name':  name,
                    'params': {
                        'row':  item,
                        'pageType': 'view'
                    },
                    'query': {
                        'businessKey': item.businessKey,
                        'type': 'transated',
                        'row':  JSON.stringify( item ),
                        'pageType': 'view',
                        't': new Date().getTime()
                    }
                });
            },
            goBack () {
                let pageType = this.$router.currentRoute.query.type;
                this.$router.push({
                    name: 'workFlow/workFlowList',
                    'query': {
                        'type': pageType
                    }
                });
            },
            getProcessMappingId() {
                var userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
                    data: {
                        entity: {
                            woCode: this.woCode,
                            orgId: userInfo.companyId,
                            deptId: userInfo.deptId
                        }
                    }
                }).then(res => {
                    if (res.data.ext.code === '2000') {
                        this.mappingId = res.data.ext.mappingId;
                    } else {
                        Toast.fail({
                            message: res.data.ext.message,
                            type: 'error',
                            duration: 1500
                        });
                    }
                });
            },
            backfilleditData(id) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/purCooperativeHeaderAbolish/view',
                    data: {
                        entity: {
                            id: id
                        }
                    }
                }).then(res => {
                    // 成功回调方法
                    var data = res.data.entity;
                    if (data) {
                        this.ruleForm = res.data.entity;
                        this.processInstanceName = util.getWorkflowCode(this.ruleForm.purCooperativeHeader.contractNo, this.processInstanceName);
                        this.variables.activitiData = res.data.entity;
                        this.getProcessMappingId();
                        this.findPurApplicationContList();
                        this.findSaleContList();
                        this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
                        if (this.$route.query.type) {
                            this.approvalShow = true;
                        }
                    }
                });
            },
            findSaleContList () {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/sale/contract/trade/saleContractOrder/appFindSaleDataByPur',
                    data: {
                        entity: {
                            contractNo: this.ruleForm.purCooperativeHeader.saleContractNo
                        }
                    }
                }).then(res => {
                    var data = res.data.entity;
                    this.saleContList = data;
                });
            },
            findPurApplicationContList () {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/application/findApplicationList',
                    data: {
                        entity: {
                            code: this.ruleForm.purCooperativeHeader.purApplicationCode
                        }
                    }
                }).then(res => {
                    var data = res.data.entity;
                    this.purApplicationContList = data;
                });
            },
            setApprovalData(row) {
                if (row && row.pid) {
                    this.ruleForm.id = row.businessKey;
                    this.taskInstanceId = row.id;
                    this.taskType = row.taskType;
                }
            },
            /**
             * 业务提交之前校验
             * */
            checkBeforeProcess(type) {
                if (type) {
                    if (type === 'beforeAddsign') {
                        this.$refs.demoTemperatureEdit.beforeAddsign();
                    } else if (type === 'afterAddsign') {
                        this.$refs.demoTemperatureEdit.afterAddsign();
                    } else if (type === 'endProcess') {
                        this.$refs.demoTemperatureEdit.endProcess();
                    } else if (type === 'repelProcess') {
                        this.repelProcess();
                    } else {
                        if (!this.taskInstanceId) {
                            var params = {
                                businessKey: this.ruleForm.id,
                                mappingId: this.mappingId,
                                processInstanceName: this.processInstanceName,
                                userName: sessionStorage.getItem('userName'),
                                variables: this.variables
                            };
                            this.startProcessAction(params);
                        } else {
                            this.$refs.demoTemperatureEdit.submit();
                        }
                    }
                } else {
                    Toast.fail({
                        message: this.$t('pur.数据校验失败'),
                        type: 'warning'
                    });
                }
            },
            // 业务发起流程
            startProcessAction(params) {
                if (params.mappingId) {
                    params.mappingId = this.mappingId;
                }
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.purCooperativeHeader.contractNo, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/pur/purCooperativeHeaderAbolish/start',
                    data: params
                })
                    .then(res => {
                        var backData = res.data.ext;
                        if (backData) {
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                            this.$refs.demoTemperatureEdit.isStart = true;
                            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
                            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
                            this.$refs.demoTemperatureEdit.isFirstNode = true;
                            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
                            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
                            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
                        } else {
                            Toast.fail({
                                message: this.$t('pur.流程发起异常'),
                                type: 'warning'
                            });
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                        }
                    })
                    .catch(_ => {
                        this.$refs.demoTemperatureEdit.submitLoading = false;
                    });
            },
            // 业务提交流程
            submitProcessAction(params) {
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.purCooperativeHeader.contractNo, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/pur/purCooperativeHeaderAbolish/submit',
                    data: params
                })
                    .then(res => {
                        if (res.data.ext.success) {
                            this.processCallback(null, null, this.taskType);
                        } else {
                            Toast.fail({
                                message: res.data.ext.msg,
                                type: 'warning'
                            });
                        }
                    })
                    .catch(_ => {
                    });
            },
            /***
             * 业务撤销流程
             */
            repelProcess() {
                var _this = this;
                _this
                    .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
                        cancelButtonClass: 'sysBackBtn'
                    })
                    .then(_ => {
                        this.$myHttp({
                            method: 'post',
                            url: '/microarch/pur/purCooperativeHeaderAbolish/undoProcess',
                            data: {
                                entity: {
                                    id: this.ruleForm.id,
                                    processInstanceId: this.ruleForm.processInstanceId
                                }
                            }
                        })
                            .then(res => {
                                if (res.data.success === undefined || res.data.success) {
                                    Toast.fail({
                                        message: this.$t('pur.撤销成功'),
                                        type: 'success'
                                    });
                                    this.processCallback(null, null, this.taskType);
                                } else {
                                    Toast.fail({
                                        message: res.data.msg,
                                        type: 'warning'
                                    });
                                }
                            })
                            .catch(_ => {
                            });
                    })
                    .catch(_ => {
                    });
            },

            /***
             * 关闭发起流程审批窗口
             * 删除流程实例，回滚业务状态
             */
            closeStartCallback(pid) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/purCooperativeHeaderAbolish/deleteProcess',
                    data: {
                        entity: {
                            id: this.ruleForm.id,
                            processInstanceId: pid
                        }
                    }
                })
                    .then(res => {
                        this.$refs.demoTemperatureEdit.dialogVisible = false;
                        this.$refs.demoTemperatureEdit.taskId = '';
                        this.$refs.demoTemperatureEdit.processInstanceId = '';
                        this.processCallback(null, null, this.taskType);
                    })
                    .catch(_ => {
                    });
            },

            /**
             * 提交之前校验
             * */
            beforeSubmitCallBack(params) {
                // 业务参数校验 此时可以从params中获取提交审批所有的参数
                // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
                // 不通过给出相应的提示或者触发其他业务操作
                // let selectUserArray = params.candidateUserIds[params.nextId]
                // console.log(selectUserArray);
                this.submitProcessAction(params);
            },
            /**
             * 流程审批之后业务数据更改
             * */
            processCallback(piid, optionType, taskType) {
                var _this = this;
                // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
                // // 成功回调方法
                // if (taskType === 'transated') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/haveList'
                //   });
                // } else if (taskType === 'waitfor') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/toDoList'
                //   });
                // } else {
                //   this.$router.push({
                //     name: 'sale/return/saleReturnApplyList'
                //   });
                //   this.$bus.emit('saleReturnApply-SaveSuccess');
                // }
                this.$router.go(-1);
            }
        },
        created() {
        },
        mounted() {
            let selectRow = JSON.parse(this.$route.query.row);
            if (selectRow) {
                this.ruleForm.id = selectRow.id;
                if (selectRow.businessKey) {
                    this.ruleForm.id = selectRow.businessKey;
                }
            }
            if (this.ruleForm.id) {
                this.backfilleditData(this.ruleForm.id);
            }

            if (selectRow) {
                this.setApprovalData(selectRow);
            }
        },
        filters: {
            setDict(v, dictName) {
                return util.setDict(v, dictName);
            }
        }
    };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
